<template>
  <div class="personalRecordBox">
    <div class="personalHeader">
      <img :src="personalIcon" alt="">
      <p>招聘事项</p>
    </div>

    <div class="tableBox">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column align="center" type="index" label="序号" width="50"></el-table-column>
        <el-table-column align="center" prop="title" label="招聘事项" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="unit" label="报考单位" width="120"></el-table-column>
        <el-table-column align="center" prop="name" label="报考岗位" width="120"></el-table-column>
        <el-table-column align="center" :formatter="formatterFun" prop="date" label="报名状态" width="120"></el-table-column>
        <el-table-column align="center" label="操作" width="130">
          <template slot-scope="scope">
            <div class="signUpFlex">
              <p class="signUpColor" @click="continueFun(scope.row)" v-if="scope.row.applyState == 3">继续</p>
              <p class="signUpColor" @click="seeFun(scope.row)" v-if="scope.row.applyState == 0||scope.row.applyState == 1||scope.row.applyState == 4">查看</p>
              <p class="signUpColor" @click="restatementFun(scope.row)" v-if="scope.row.applyState == 2">完善</p>
              <p class="signUpColor" @click="revokeFun(scope.row)" v-if="scope.row.applyState == 2||scope.row.applyState == 3">撤销</p>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        background
        @current-change="currentChange"
        layout="prev, pager, next"
        :page-size="10"
        :current-page="page"
        :total="total">
      </el-pagination>
      </div>

    <signupProcess @getData="getData" :positionId="positionId" v-if="processVisible" :id="id" :type="type" v-model="processVisible" />
  </div>
</template>
<script>
import personalIcon from '@/assets/personal-icon.png'
import signupProcess from '@/components/signupProcess'
import {getOwnListExamineeByPage,deleteExaminee} from '@/api/personalRecord/personalRecord'
export default {
  components:{
    signupProcess
  },
  data() {
    return {
      personalIcon,
      total:0,
      page:1,
      dialogVisible:false,
      processVisible:false,
      tableData:[],
      type:null,
      id:'',
      positionId:''
    }
  },
  mounted() {
    this.getData()
  },
  methods:{
    // 获取列表
    getData(){
      let data = {
        page:this.page,
        limit:10,
        ownToken:this.$ownToken
      }
      getOwnListExamineeByPage(data).then(res=>{
        if(res.status == 200){
          this.tableData = res.data.rows;
          this.total = res.data.total;
        }
      })
    },
    // 继续
    continueFun(row){
      this.type = 3;
      this.id = row.id;
      this.positionId = row.positionId;
      this.processVisible = true;
    },
    // 查看
    seeFun(row){
      this.type = 2;
      this.id = row.id;
      this.positionId = row.positionId;
      this.processVisible = true;
    },
    // 重报
    restatementFun(row){
      this.type = 4;
      this.id = row.id;
      this.positionId = row.positionId;
      this.processVisible = true;
    },
    // 撤销
    revokeFun(row){
      this.$confirm('报名撤销后将彻底清除记录，但可以在报名期限内发起新的报名。您确认要撤销此次记录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteExaminee({id:row.id,ownToken:this.$ownToken}).then(res=>{
          if(res.status == 200){
            this.$message.success('撤销成功！')
            this.getData()
          }else{
            this.$message.error(res.message)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消撤销'
        });          
      });
      
    },
    // 报名状态
    formatterFun(row,column,cellValue, index){
      let str = '';
      if(row.applyState==0){
        str = '已通过'
      }
      if(row.applyState==1){
        str = '未通过'
      }
      if(row.applyState==2){
        str = '待完善材料'
      }
      if(row.applyState==3){
        str = '未提交'
      }
      if(row.applyState==4){
        str = '待审核'
      }
      return str;
    },
    // 报名
    signUpFun(){
      this.dialogVisible = true;
    },
    // 分页
    currentChange(val){
      this.page = val;
      this.getData()
    },
  }
}
</script>
<style lang="scss" scoped>
  .personalRecordBox{
    .personalHeader{
      display: flex;
      padding: 25px 20px;
      p{
        margin-left: 10px;
        color: #bf162a;
        font-weight: 600;
      }
    }

    .signUpFlex{
      display: flex;
      justify-content: center;
      .signUpColor{
        color: rgb(17, 166, 234);
        cursor: pointer;
        margin-right: 10px;
      }
    }
  }
</style>