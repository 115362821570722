import axios from '@/utils/axios'

// 个人报名记录
export function getOwnListExamineeByPage(params){
  return axios({
    url:'/api/pc/backstage/examinee/getOwnListExamineeByPage',
    method:'get',
    params
  })
}

// 撤销
export function deleteExaminee(params){
  return axios({
    url:'/api/pc/backstage/examinee/deleteExaminee',
    method:'get',
    params
  })
}
